<template>
  <div class="upload-many">
    <Uploader
      v-if="isRendered.uploader"
      :is-document="isDocument"
      :mime-allowed="acceptMime"
      :max-file-size-mb="maxFileSizeMb"
      @error="emitError"
      @upload-file="processUpload"
    >
      <template #default="{ data, actions, events }">
        <div
          :class="{
            'btn-outline-primary': data.dragover,
            'btn-primary': !data.dragover,
          }"
          class="upload-many-dropzone btn btn-lg"
          v-on="events.drag"
          @drop="(e) => onChange(actions, e)"
        >
          <i class="fas fa-upload mr-2"></i>

          <label>
            <input
              ref="upload"
              :accept="acceptFormat"
              type="file"
              @change="(e) => onChange(actions, e)"
              @drop="(e) => onChange(actions, e)"
            />
          </label>

          <span>
            {{ t('select_file') }}
          </span>
        </div>
      </template>
    </Uploader>

    <ul v-show="hasFiles" class="list-unstyled mb-0 mt-4">
      <li
        v-for="file in localFiles"
        :key="file.id"
        class="d-flex flex-wrap align-items-center mb-2"
      >
        <template v-if="isShowingImages">
          <template v-if="file.isImage">
            <div class="position-relative">
              <img
                :alt="file.name"
                :src="getDownloadUrl(file)"
                class="upload-many__img mb-2"
              />

              <div :class="$style.imageActions">
                <div
                  v-for="(btn, i) in imageActions"
                  :key="i"
                  v-tippy
                  :content="btn.text"
                  :class="$style.imageAction"
                  class="btn btn-secondary"
                  @click="btn.onClick(file)"
                >
                  <i :class="btn.icon"></i>
                </div>
              </div>
            </div>

            <ModalEditFileUpload
              ref="modalEditFileUpload"
              :file="selectFile"
              :original-url="originalUrl"
              @upload-file="onAddCommentAttachment"
            />

            <div class="w-100"></div>
          </template>

          <i
            v-else-if="file.mime.includes('image')"
            class="fas fa-file-image upload-many__img-icon mr-2"
          ></i>
        </template>

        <span :class="downloadBtnClass" class="btn cursor-default px-0">
          <span class="px-3 p-2">
            {{ file.name || t('untitled') }}
          </span>

          <span
            v-if="isRendered.btnDownload"
            class="p-2 cursor-pointer text-secondary border-left"
            @click="onClickBtnDownload(file)"
          >
            <i class="fas fa-download fa-fw"></i>
          </span>

          <span
            v-if="isRendered.btnRemove"
            class="p-2 cursor-pointer text-secondary border-left"
            @click="onClickBtnRemove(file)"
          >
            <i class="fas fa-times fa-fw"></i>
          </span>
        </span>
      </li>
    </ul>

    <ModalConfirmRemoveFileUploadMany ref="modalConfirmRemoveFile" />
  </div>
</template>

<script>
import ApiDownload from 'Api/Download'
import { ApiFileImageRotate } from 'ApiRest/Api/File/Image/Rotate'
import ModalConfirmRemoveFileUploadMany from 'CommonComponents/ModalConfirmRemoveFileUploadMany.vue'
import ModalEditFileUpload from 'CommonComponents/ModalEditFileUpload.vue'
import Uploader from 'CommonComponents/Uploader.vue'
import { cloneDeep } from 'lodash'

const ROTATE_ANGLE = 90

export default {
  name: 'FileUploadMany',

  components: {
    ModalConfirmRemoveFileUploadMany,
    ModalEditFileUpload,
    Uploader,
  },

  model: {
    prop: 'files',
    event: 'change',
  },

  props: {
    /**
     * v-model
     */
    files: {
      type: Array,
      default: () => [],
    },

    acceptFormat: {
      type: String,
      default: '',
    },

    acceptMime: {
      type: Array,
      default: null,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    isDocument: {
      type: Boolean,
      default: false,
    },

    isClearable: {
      type: Boolean,
      default: true,
    },

    isDownloadable: {
      type: Boolean,
      default: true,
    },

    isUploadable: {
      type: Boolean,
      default: true,
    },

    isShowingImages: {
      type: Boolean,
      default: false,
    },

    isPrivate: {
      type: Boolean,
      default: false,
    },

    isConfirmRemove: {
      type: Boolean,
      default: false,
    },

    customDownloadUrl: {
      type: Function,
      default: null,
    },

    customUploadUrl: {
      type: String,
      default: null,
    },

    downloadBtnClass: {
      type: String,
      default: 'btn-light text-secondary text-truncate',
    },

    maxFileSizeMb: {
      type: Number,
      default: 50,
    },
  },

  emits: [
    'add-comment-attachment',
  ],

  data() {
    return {
      localFiles: [],
      selectFile: null,
      originalUrl: null,
    }
  },

  computed: {
    // TODO: i18n
    imageActions() {
      return [
        {
          icon: 'fas fa-pencil-alt',
          text: 'Редактировать',
          onClick: this.onClickBtnEdit,
        },

        {
          icon: 'fas fa-sync',
          text: this.t('rotate_image_to'),
          onClick: this.onClickBtnRotateImage,
        },
      ]
    },

    hasFiles() {
      return this.localFiles?.length > 0
    },

    isRendered() {
      return {
        btnDownload: this.isDownloadable,
        btnRemove: this.isClearable && !this.readonly,
        uploader: this.isUploadable && !this.readonly,
      }
    },
  },

  watch: {
    files: {
      handler() {
        this.localFiles = cloneDeep(this.files)
      },

      deep: true,
      immediate: true,
    },
  },

  methods: {
    emitChange() {
      this.$emit('change', this.localFiles)
    },

    emitRotate() {
      this.$emit('rotate', this.localFiles)
    },

    emitError(message) {
      this.$emit('error', message)
    },

    emitRemove(file) {
      this.$emit('remove', file, this.localFiles)
    },

    onChange(actions, payload) {
      if (this.customUploadUrl === null) {
        return actions.processUploadAny(payload, this.isPrivate)
      }

      return actions.processUploadCustomEndpoint(payload, this.customUploadUrl)
    },

    processUpload(file) {
      if (!this.readonly) {
        this.localFiles.push(file)
        this.emitChange()
      }
    },

    onAddCommentAttachment(file) {
      this.$emit('add-comment-attachment', file)
    },

    getDownloadUrl(file) {
      // Необходимо добавлять "хвост" для того,
      // чтобы браузер не кешировал изображения во время манипуляций с ними,
      // например, при повороте изображения.
      const hash = Date.now()

      if (this.customDownloadUrl) {
        return `${this.customDownloadUrl(file)}?${hash}`
      }

      return `${ApiDownload.getFileUrl(file.id)}?${hash}`
    },

    /**
     * @param {Object} file
     */
    onClickBtnDownload(file) {
      window.location.href = this.getDownloadUrl(file)
    },

    removeFile(file) {
      this.emitRemove(file)

      this.localFiles = this.localFiles.filter(({ id }) => id !== file.id)

      this.emitChange()
    },

    async showModalConfirm(file) {
      const modal = this.$refs.modalConfirmRemoveFile

      modal.show(file)

      return new Promise((resolve) => {
        modal.$on('confirm', () => resolve(true))
        modal.$on('cancel', () => resolve(false))
      })
    },

    showModalEdit() {
      const modal = this.$refs.modalEditFileUpload[0]

      modal.show()
    },

    /**
     * @param {Object} file
     */
    async onClickBtnRemove(file) {
      if (this.isConfirmRemove) {
        const isConfirmed = await this.showModalConfirm(file)

        if (isConfirmed) {
          this.emitRemove(file)
        }
      } else {
        this.removeFile(file)
      }
    },

    async onClickBtnEdit(file) {
      await this.setSelectFile(file)
      this.showModalEdit()
    },

    async setSelectFile(file) {
      this.selectFile = file
      this.originalUrl = await this.getBase64Image(file)
    },

    getBase64Image(file) {
      const image = new Image()

      image.crossOrigin = 'use-credentials'
      image.src = this.getDownloadUrl(file)

      return new Promise((resolve) => {
        image.addEventListener('load', () => {
          const canvas = document.createElement('canvas')
          const context = canvas.getContext('2d')

          canvas.height = file.height
          canvas.width = file.width
          context.drawImage(image, 0, 0)
          const dataURL = canvas.toDataURL(file.mime)

          dataURL.replace('data:', '').replace(/^.+,/, '')

          resolve(dataURL)
        })
      })
    },

    async onClickBtnRotateImage(file) {
      const { data } = await ApiFileImageRotate.patch(file.id, ROTATE_ANGLE)

      const index = this.localFiles.findIndex(
        (localFile) => localFile.id === file.id,
      )

      this.localFiles.splice(index, 1, data)

      this.emitRotate()
    },

    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(`common_components.file_upload_many.${name}`)
    },
  },
}
</script>

<style lang="scss" module>
.imageActions {
  $gutter: 20px;

  position: absolute;
  right: $gutter;
  bottom: $gutter;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
}

.imageAction {
  width: 40px;
  padding-right: 0 !important;
  padding-left: 0 !important;
}
</style>
